<template>
  <div class="content-wrapper pad">
    <section class="stories-list">
      <loadMore
        :endpoint="storiesEndpoint"
        :item-class-func="storyItemAdditionalClasses"
        width="column is-flex-tablet is-column"
      >
        <template slot="item" slot-scope="props">
          <story-card
            :story="props.item"
            :newsroom-prop="newsroom"
            :show-pinned-posts="true"
            :show-less-info="true"
          />
        </template>
      </loadMore>
    </section>
  </div>
</template>

<script>
import StoryCard from '@/components/storySearch/StoryCard.vue'
import NewsroomChild from '@/pages/newsroom/NewsroomChild'

export default {
  components: {
    StoryCard
  },

  extends: NewsroomChild,

  data () {
    return {
      pageName: 'Stories'
    }
  },

  computed: {
    storiesEndpoint () { return `stories?newsrooms[]=${this.newsroom.id}&sort[]=newest` }
  },

  methods: {
    storyItemAdditionalClasses (story) {
      return story.is_pinned
        ? 'is-full-tablet is-full-desktop'
        : 'is-half-tablet is-one-third-desktop'
    }
  }
}
</script>
